module services {
    export module master {
        export class variableDocsTemplateService implements interfaces.master.IVariableDocsTemplateService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }
            
            getList(): ng.resource.IResourceClass<interfaces.master.IVariableDocumentDisplay> {

                return this.$resource<interfaces.master.IVariableDocumentDisplay>(this.ENV.DSP_URL + "VariableDocsTemplate/GetList", {
                });
            }

            getDropDownList(ownerEntityId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "VariableDocsTemplate/GetDropdownList", {
                    ownerEntityId: ownerEntityId
                });
            }

            getDetail(variableDocsTemplateId: number): ng.resource.IResourceClass<interfaces.master.IVariableDocsTemplateViewModel> {
                return this.$resource<interfaces.master.IVariableDocsTemplateViewModel>(this.ENV.DSP_URL + "VariableDocsTemplate/GetDetail", {
                    variableDocuumentTemplateId: variableDocsTemplateId
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }

            getCustomerList(variableDocsTemplateId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "VariableDocsTemplate/GetCustomerList", {
                    variableDocsTemplateId: variableDocsTemplateId
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "VariableDocsTemplate/Save");
            }

            getVariableDocTypes(ownerEntityId: number): ng.resource.IResourceClass<interfaces.master.IVariableDocType> {

                return this.$resource<interfaces.master.IVariableDocType>(this.ENV.DSP_URL + "VariableDocsTemplate/GetVariableDocTypes", {
                    ownerEntityId: ownerEntityId
                });
            }

        }
    }
    angular.module("app").service("variableDocsTemplateService", services.master.variableDocsTemplateService);
}